<template>
  <body-layout title="任务"></body-layout>
</template>

<script>
import BodyLayout from '../../components/page/BodyLayout.vue'
export default {
  data(){
    return{
      
 
BodyLayout   }
  },
  methods:{},
  created(){}
}
</script>

<style lang='stylus'>

</style>