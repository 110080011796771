var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isUseBodyLayoutStyle
        ? _c("a-card", { attrs: { bordered: false } }, [
            !_vm.noHeader
              ? _c(
                  "div",
                  { staticClass: "flex pb-4 border-bottom" },
                  [
                    _c("div", { staticClass: "flex-1 flex items-center" }, [
                      _c(
                        "div",
                        { staticClass: "text-lg font-medium" },
                        [
                          _vm._v(
                            "\n          " + _vm._s(_vm.title) + "\n          "
                          ),
                          _vm._t("search"),
                        ],
                        2
                      ),
                    ]),
                    _vm._t("actions", function () {
                      return [_c("div", { staticStyle: { height: "32px" } })]
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _c("div", { staticClass: "py-2" }, [_vm._t("default")], 2),
          ])
        : _c("div", [
            !_vm.noHeader
              ? _c(
                  "div",
                  { staticClass: "flex pb-4 border-bottom" },
                  [
                    _c("div", { staticClass: "flex-1 flex items-center" }, [
                      _c(
                        "div",
                        { staticClass: "text-lg font-medium" },
                        [
                          _vm._v(
                            "\n          " + _vm._s(_vm.title) + "\n          "
                          ),
                          _vm._t("search"),
                        ],
                        2
                      ),
                    ]),
                    _vm._t("actions", function () {
                      return [_c("div", { staticStyle: { height: "32px" } })]
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _c("div", { staticClass: "py-2" }, [_vm._t("default")], 2),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }