<template>
  <div>
    <a-card :bordered="false" v-if="!isUseBodyLayoutStyle">
      <div class="flex pb-4 border-bottom" v-if="!noHeader">
        <div class="flex-1 flex items-center">
          <div class="text-lg font-medium">
            {{ title }}
            <slot name="search"></slot>
          </div>
        </div>
        <slot name="actions"><div style="height: 32px"></div></slot>
      </div>
      <!-- <div slot="extra">
      <slot name="actions"></slot>
    </div> -->
      <div class="py-2">
        <slot></slot>
      </div>
    </a-card>
    <div v-else>
       <div class="flex pb-4 border-bottom" v-if="!noHeader">
        <div class="flex-1 flex items-center">
          <div class="text-lg font-medium">
            {{ title }}
            <slot name="search"></slot>
          </div>
        </div>
        <slot name="actions"><div style="height: 32px"></div></slot>
      </div>
       <div class="py-2">
             <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    noHeader: {
      type: Boolean,
      default: false
    },
    isUseBodyLayoutStyle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  methods: {},
  created() {}
}
</script>

<style lang="stylus"></style>
